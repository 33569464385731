.loader_container .loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  opacity: 0.5;
  border: 3px solid #09acfd;
  -webkit-animation: loader 1s ease-in-out infinite alternate;
  animation: loader 1s ease-in-out infinite alternate;
}

.loader_container .loader:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #09acfd;
}

.loader_container .loader:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #09acfd;
}

@keyframes loader {
  from {
    transform: rotate(0deg) scale(1, 1);
    border-radius: 10px;
  }
  to {
    transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg) scale(1, 1);
    border-radius: 10px;
  }
  to {
    -webkit-transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}

/* loader 2 */
.spinner-container .spinner {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 22;
}

.spinner-container svg {
  max-width: 10rem;
  animation: rotate 3.6s linear infinite;
}

.spinner-container circle {
  fill: none;
  stroke: #9ad0ea;
  /* stroke: #1DA1F1; */
  stroke-width: 6px;
  stroke-dasharray: 300;
  animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
}

@keyframes outline {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 300;
  }
  100% {
    stroke-dashoffset: 600;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(-1turn);
  }
}
